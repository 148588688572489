const GET_ACTIVITY_FOR_TEACHER_FEEDBACK = gql`
  query GetActivityForTeacherFeedback($activityId: Int!, $studentId: Int!) {
    FindActivityForTeacherFeedback(
      activityId: $activityId
      studentId: $studentId
    ) {
      id
      typology
      instruction_en
      instruction_pt_br
      feedbacks {
        type
        title_en
        title_pt_br
        explanation_en
        explanation_pt_br
      }
      answerFields {
        text
        id
        coverMedia
        order
        answers {
          id
          answer
          hit
          answerFieldId
          createdAt
          teacherFeedback
          teacherFeedbackAt
          teacherFeedbackBy
        }
        choices {
          id
          text
          isCorrect
          coverMedia
          order
        }
      }
    }
  }
`

const LIST_SCHOOL_CLASSES_WITH_TESTS = gql`
  query ListSchoolClassesWithTests {
    ListSchoolClassesWithTests {
      id
      name
      schoolName
      classShift
      pendingTestsCount
      module {
        id
        title
      }
    }
  }
`

const LIST_SCHOOL_CLASS_STUDENTS_WITH_TESTS = gql`
  query ListSchoolClassStudentsWithTests($schoolClassId: Int!) {
    ListSchoolClassStudentsWithTests(schoolClassId: $schoolClassId) {
      schoolClass {
        id
        name
      }

      students {
        id
        name
        test {
          id
          grade
          beingGraded
        }
      }
    }
  }
`

const CREATE_TEACHER_FEEDBACK = gql`
  mutation CreateTeacherFeedback($input: TeacherFeedbackInput!) {
    CreateTeacherFeedback(input: $input) {
      id
    }
  }
`

const GET_TEACHER_SCHOOL_CLASS = gql`
  query GetTeacherSchoolClass($id: Int!) {
    FindTeacherSchoolClass(id: $id) {
      id
      name
      schoolName
    }
  }
`

const GET_TEACHER_SCHOOL_CLASSES = gql`
  query GetTeacherSchoolClasses($moduleId: Int, $legacyClassId: Int) {
    FilterTeacherSchoolClasses(
      moduleId: $moduleId
      legacyClassId: $legacyClassId
    ) {
      id
      name
      schoolName
      classShift
      moduleId
      pendingCorrectionActivities
    }
  }
`

const GET_TEACHER_SCHOOL_CLASSES_STUDENT = gql`
  query GetTeacherSchoolClassStudent($id: Int!) {
    FindTeacherSchoolClassStudent(id: $id) {
      levelId
      studentId
      studentName
    }
  }
`

const GET_TEACHER_SCHOOL_CLASSES_STUDENTS = gql`
  query GetTeacherSchoolClassStudents($schoolClassId: Int!) {
    FilterTeacherSchoolClassStudents(schoolClassId: $schoolClassId) {
      levelName
      levelId
      pendingCorrectionActivities
      schoolClassName
      schoolName
      studentId
      studentName
    }
  }
`

const GET_TEACHER_WRITING_ANSWERS = gql`
  query GetTeacherWritingAnswers($levelId: Int!, $studentId: Int!) {
    TeacherWritingAnswers(levelId: $levelId, studentId: $studentId) {
      answers {
        levelTitle
        unitId
        unitOrder
        unitTitle
        lessonOrder
        lessonId
        lessonTitle
        activityOrder
        activityId
        latestAnswerHit
        teacherFeedbackAt
        latestUserAnswerAt
      }
    }
  }
`

const GET_TEACHER_STUDENT_TEST_FOR_GRADE = gql`
  query GetTeacherStudentTestForGrade($studentTestId: Int!) {
    ListTestActivitiesForGrading(studentTestId: $studentTestId) {
      id
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      estimatedTime
      maxAnswerTime
      teacherFeedback
      grade
      score
      hit
      availableResults
      gradedAt
      gradeWeight
      modules {
        moduleId
        order
      }
      answerFields {
        coverMedia
        id
        text
        order
        choices {
          isCorrect
          id
          text
          order
          coverMedia
        }
        answers {
          id
          hit
          answer
          answerFieldId
          createdAt
          teacherFeedback
        }
      }
    }
  }
`

const SEND_TEACHER_GRADE = gql`
  mutation SendTeacherGrade(
    $activityId: Int!
    $studentTestId: Int!
    $input: GradeTestActivityInput!
  ) {
    GradeTestActivity(
      activityId: $activityId
      studentTestId: $studentTestId
      input: $input
    )
  }
`

const GET_TEACHER_GRADE_STUDENT = gql`
  query GetTeacherGradeStudent($id: Int!) {
    FindTeacherSchoolClassStudent(id: $id) {
      levelName
      schoolClassName
      schoolName
      studentName
    }
  }
`

const GET_TEACHER_STUDENT_ACTIVITIES_GRADE = gql`
  query GetTeacherStudentGradeResult($studentTestId: Int!) {
    ListTestActivitiesForGrading(studentTestId: $studentTestId) {
      id
      grade
      typology
      score
    }
  }
`

const FINISH_TEACHER_STUDENT_GRADE = gql`
  mutation FinishTeacherStudentGrade($studentTestId: Int!) {
    FinishGradingTest(studentTestId: $studentTestId) {
      gradedAt
      grade
    }
  }
`

const GET_TEACHER_GRADE_STUDENT_NAME = gql`
  query GetTeacherGradeStudentName($id: Int!) {
    FindTeacherSchoolClassStudent(id: $id) {
      studentName
    }
  }
`

const GET_TEACHER_STUDENT_FINAL_GRADE = gql`
  query GetStudentGradeFinalGrade($studentTestId: Int!) {
    FindStudentGrade(studentTestId: $studentTestId) {
      gradedAt
      grade
    }
  }
`

export {
  GET_ACTIVITY_FOR_TEACHER_FEEDBACK,
  CREATE_TEACHER_FEEDBACK,
  GET_TEACHER_SCHOOL_CLASS,
  GET_TEACHER_SCHOOL_CLASSES,
  GET_TEACHER_SCHOOL_CLASSES_STUDENT,
  GET_TEACHER_SCHOOL_CLASSES_STUDENTS,
  GET_TEACHER_WRITING_ANSWERS,
  LIST_SCHOOL_CLASSES_WITH_TESTS,
  GET_TEACHER_STUDENT_TEST_FOR_GRADE,
  SEND_TEACHER_GRADE,
  GET_TEACHER_GRADE_STUDENT,
  LIST_SCHOOL_CLASS_STUDENTS_WITH_TESTS,
  GET_TEACHER_STUDENT_ACTIVITIES_GRADE,
  FINISH_TEACHER_STUDENT_GRADE,
  GET_TEACHER_GRADE_STUDENT_NAME,
  GET_TEACHER_STUDENT_FINAL_GRADE,
}
