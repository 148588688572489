import { ActivityProvider } from 'src/providers/learning/activity/ActivityProvider'

type LearningTeacherStudentGradeLayoutProps = {
  children?: React.ReactNode
}

const LearningTeacherStudentGradeLayout = ({
  children,
}: LearningTeacherStudentGradeLayoutProps) => {
  return <ActivityProvider>{children}</ActivityProvider>
}

export default LearningTeacherStudentGradeLayout
