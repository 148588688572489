import { useState, useEffect } from 'react'

import { Badge, Text, rem } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { IconHourglassLow } from '@tabler/icons-react'

import { diffSeconds } from 'src/helpers/date'

type ActivityCountdownProps = {
  mustFinishAt: string
}

const floatToTime = (floatSeconds: number) => {
  const minutes = Math.floor(floatSeconds / 60)
  const seconds = floatSeconds % 60

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

const TestCountdown = ({ mustFinishAt }: ActivityCountdownProps) => {
  const initialTotalSeconds = diffSeconds(new Date(mustFinishAt), new Date())

  const [remainingTime, setTime] = useState(floatToTime(initialTotalSeconds))

  const interval = useInterval(
    () =>
      setTime(() => {
        const newTotalSeconds = diffSeconds(new Date(mustFinishAt), new Date())

        if (newTotalSeconds <= 0) {
          interval.stop()
          return '00:00'
        }

        return floatToTime(newTotalSeconds)
      }),
    1000
  )

  useEffect(() => {
    interval.start()
    return () => interval.stop()
  }, [interval])

  const color = 'var(--mantine-color-yellow-8)'

  return (
    <Badge
      w={rem(104)}
      size="xl"
      bg="yellow.0"
      leftSection={<IconHourglassLow color={color} size={16} />}
    >
      <Text c={color} fw="500">
        {remainingTime}
      </Text>
    </Badge>
  )
}

export default TestCountdown
