import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react'

import { FeedbackEnum } from 'src/enums/learning/activity/FeedbackEnum'
type ActivityResolverProviderProps = {
  children: ReactNode
}

type ActivityFeedback = {
  type: FeedbackEnum
  explanation: string
  title: string
  isFeedbackVisible: boolean
  isCorrectAnswerVisible: boolean
  attempts?: number
  language: string
}

interface ActivityResolverState {
  answer: unknown[]
  answerFeedback: ActivityFeedback
  answerThreshold?: number
  setAnswer: (answer: unknown[]) => void
  setAnswerFeedback: (answerFeedback: object) => void
  resetAnswer: () => void
  resetAnswerFeedback: () => void
  hideAnswerFeedback: () => void
  setCorrectAnswerVisible: () => void
  setFeedbackLanguage: () => void
  setAnswerThreshold: (answerThreshold: number | undefined) => void
}

const emptyAnswerFeedback = {
  type: null,
  explanation: '',
  title: '',
  isFeedbackVisible: false,
  isCorrectAnswerVisible: false,
  attempts: 0,
  language: 'en',
  answerThreshold: undefined,
}

const defaultContext: ActivityResolverState = {
  answer: [],
  answerFeedback: emptyAnswerFeedback,
  answerThreshold: undefined,
  setAnswer: () => {},
  setAnswerFeedback: () => {},
  resetAnswer: () => {},
  resetAnswerFeedback: () => {},
  hideAnswerFeedback: () => {},
  setCorrectAnswerVisible: () => {},
  setFeedbackLanguage: () => {},
  setAnswerThreshold: () => {},
}

const ActivityResolverContext =
  createContext<ActivityResolverState>(defaultContext)

const useActivityResolver = (): ActivityResolverState => {
  const context = useContext(ActivityResolverContext)
  return context
}

const ActivityResolverProvider = ({
  children,
}: ActivityResolverProviderProps) => {
  const [answer, setAnswer] = useState<unknown[]>([])
  const [answerThreshold, setAnswerThreshold] = useState<number | undefined>(
    undefined
  )

  const [answerFeedback, setAnswerFeedback] =
    useState<ActivityFeedback>(emptyAnswerFeedback)

  const resetAnswer = useCallback(() => setAnswer([]), [])

  const resetAnswerFeedback = useCallback(
    () => setAnswerFeedback(emptyAnswerFeedback),
    []
  )

  const hideAnswerFeedback = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      isFeedbackVisible: false,
    }))
  }

  const setCorrectAnswerVisible = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      isFeedbackVisible: false,
      isCorrectAnswerVisible: true,
    }))
  }

  const setFeedbackLanguage = () => {
    setAnswerFeedback((prev) => ({
      ...prev,
      language: prev.language === 'en' ? 'pt_br' : 'en',
    }))
  }

  const value = {
    answer,
    answerFeedback,
    answerThreshold,
    setAnswer,
    setAnswerFeedback,
    resetAnswer,
    resetAnswerFeedback,
    hideAnswerFeedback,
    setCorrectAnswerVisible,
    setFeedbackLanguage,
    setAnswerThreshold,
  }

  return (
    <ActivityResolverContext.Provider value={value}>
      {children}
    </ActivityResolverContext.Provider>
  )
}
export {
  ActivityResolverProvider,
  useActivityResolver,
  ActivityResolverContext,
}
