const GET_MODULE = gql`
  query FindModule($id: Int!) {
    FindModule(id: $id) {
      id
      title
      coverMedia
      subModules {
        id
        order
        subModule {
          id
          title
          category
        }
      }
    }
  }
`

const GET_MODULES = gql`
  query FilterModules(
    $category: ModuleCategoryEnum
    $courseId: Int
    $parentModuleId: Int
    $status: StatusEnum
    $courseSegment: CourseSegmentEnum
  ) {
    FilterModules(
      category: $category
      courseId: $courseId
      parentModuleId: $parentModuleId
      status: $status
      courseSegment: $courseSegment
    ) {
      id
      slug
      title
      description
      category
      coverMedia
      course {
        id
      }
      subModules {
        order
        subModule {
          id
          title
          description
          coverMedia
          category
        }
      }
    }
  }
`

const GET_SIMPLIFIED_MODULES = gql`
  query SimplifiedFilterModules(
    $category: ModuleCategoryEnum
    $courseId: Int
    $parentModuleId: Int
    $status: StatusEnum
    $courseSegment: CourseSegmentEnum
  ) {
    FilterModules(
      category: $category
      courseId: $courseId
      parentModuleId: $parentModuleId
      status: $status
      courseSegment: $courseSegment
    ) {
      id
      title
    }
  }
`

const GET_ACTIVITY = gql`
  query FindActivity($id: Int!) {
    FindActivity(id: $id) {
      id
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      estimatedTime
      maxAnswerTime
      modules {
        moduleId
        order
      }
      answerFields {
        coverMedia
        id
        text
        order
        choices {
          isCorrect
          id
          text
          order
          coverMedia
        }
        answers {
          id
          answer
          hit
          answerFieldId
          createdAt
          teacherFeedback
          teacherFeedbackAt
          teacherFeedbackBy
        }
      }
    }
  }
`

const GET_ACTIVITIES = gql`
  query FilterActivities($courseId: Int, $moduleId: Int, $status: StatusEnum) {
    FilterActivities(
      courseId: $courseId
      moduleId: $moduleId
      status: $status
    ) {
      id
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      estimatedTime
      maxAnswerTime
      modules {
        moduleId
        order
      }
      answerFields {
        coverMedia
        id
        text
        order
        choices {
          isCorrect
          id
          text
          order
          coverMedia
        }
        answers {
          id
          answer
          hit
          answerFieldId
          createdAt
        }
      }
    }
  }
`

const GET_SIMPLIFIED_MODULE_BY_COURSE = gql`
  query FindCourse($id: Int!) {
    FindCourse(id: $id) {
      modules {
        id
        title
        slug
      }
    }
  }
`

const GET_SUBMODULES = gql`
  query FilterSubModules(
    $parentModuleId: Int
    $status: StatusEnum
    $category: ModuleCategoryEnum
  ) {
    FilterModules(
      parentModuleId: $parentModuleId
      status: $status
      category: $category
    ) {
      id
      slug
      title
      description
      category
      subModules {
        order
        id
        moduleId
        subModule {
          id
          title
          description
          coverMedia
          slug
          category
        }
      }
    }
  }
`

const GET_RESOURCES = gql`
  query FilterResources($moduleId: Int, $parentModuleId: Int) {
    FilterResources(moduleId: $moduleId, parentModuleId: $parentModuleId) {
      id
      name
      media
      order
      module {
        id
        title
        module {
          order
        }
      }
    }
  }
`
const CREATE_ANSWER_ACTIVITY = gql`
  mutation AnswerActivity($input: UserAnswerInput!) {
    AnswerActivity(input: $input) {
      feedback {
        title_en
        title_pt_br
        explanation_en
        explanation_pt_br
        type
      }
      answers {
        id
        answer
        hit
        answerFieldId
      }
    }
  }
`

const GET_COURSE_PROGRESS = gql`
  query GetCourseProgress($courseId: Int!) {
    CourseProgress(courseId: $courseId) {
      modulesProgress {
        id
        completed
        total
      }
    }
  }
`

const GET_WRITING_ACTIVITIES = gql`
  query ListWritingAnswers($levelId: Int!) {
    WritingAnswers(levelId: $levelId) {
      answers {
        levelTitle
        unitId
        unitOrder
        unitTitle
        lessonOrder
        lessonId
        lessonTitle
        activityOrder
        activityId
        latestAnswerHit
        teacherFeedbackAt
        latestUserAnswerAt
      }
    }
  }
`

const GET_TEST = gql`
  query GetTest($id: Int!) {
    FindTest(id: $id) {
      startAt
      finishAt
      testGroupId
      testGroup {
        id
        title
      }
    }
  }
`

const GET_STUDENT_TEST = gql`
  query GetStudentTest($id: Int!) {
    FindStudentTest(id: $id) {
      id
      testId
      testModuleId
      startedAt
      mustFinishAt
      finishedAt
    }
  }
`

const START_TEST = gql`
  mutation StartTest($testId: Int, $accepted: Boolean) {
    StartTest(testId: $testId, accepted: $accepted) {
      studentTestId
      startedAt
    }
  }
`

const GET_TEST_ACTIVITIES = gql`
  query GetTestActivities($studentTestId: Int) {
    GetTestActivities(studentTestId: $studentTestId) {
      id
      answered
      instruction_en
      instruction_pt_br
      contextMedia
      contextText
      typology
      order
      answerFields {
        id
        coverMedia
        text
        order
        choices {
          id
          coverMedia
          order
          text
        }
        answers {
          id
          answer
          answerFieldId
        }
      }
    }
  }
`

const CREATE_ANSWER_TEST_ACTIVITY = gql`
  mutation AnswerTestActivity(
    $studentTestId: Int
    $userAnswerFields: UserAnswerInput!
  ) {
    AnswerTestActivity(
      studentTestId: $studentTestId
      userAnswerFields: $userAnswerFields
    ) {
      answers {
        id
        answer
        answerFieldId
      }
    }
  }
`

const CREATE_FINISH_TEST = gql`
  mutation CreateFinishTest($studentTestId: Int) {
    FinishTest(studentTestId: $studentTestId) {
      ok
    }
  }
`

const GET_STUDENT_TESTS = gql`
  query GetStudentTests {
    ListStudentTests {
      testsAvailableToStart {
        id
        title
        startAt
        finishAt
      }
      existingTests {
        id
        title
        startedAt
        mustFinishAt
        finishedAt
      }
    }
  }
`

export {
  GET_MODULE,
  GET_MODULES,
  GET_ACTIVITY,
  GET_ACTIVITIES,
  GET_SIMPLIFIED_MODULE_BY_COURSE,
  GET_SUBMODULES,
  GET_RESOURCES,
  CREATE_ANSWER_ACTIVITY,
  GET_COURSE_PROGRESS,
  GET_WRITING_ACTIVITIES,
  GET_SIMPLIFIED_MODULES,
  GET_TEST,
  START_TEST,
  GET_STUDENT_TEST,
  GET_TEST_ACTIVITIES,
  CREATE_ANSWER_TEST_ACTIVITY,
  CREATE_FINISH_TEST,
  GET_STUDENT_TESTS,
}
