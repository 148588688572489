import {
  Anchor,
  Stack,
  Grid,
  Group,
  LoadingOverlay,
  Text,
  Title,
  rem,
  Box,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { GetStudentTests } from 'types/graphql'

import { routes, Link } from '@redwoodjs/router'
import { useQuery, Metadata } from '@redwoodjs/web'

import BackNavigation from 'src/components/learning/BackNavigation/BackNavigation'
import Levels from 'src/components/learning/Levels/Levels'
import NavBar from 'src/components/learning/NavBar/NavBar'
import TestCard from 'src/components/learning/TestCard/TestCard'
import SectionDivider from 'src/components/shared/SectionDivider/SectionDivider'
import { GET_STUDENT_TESTS } from 'src/graphql/learning/learning'
import { formatDate, formatTime } from 'src/helpers/date'
import { useIsSmallScreen } from 'src/hooks/shared/screen'

export function TestsPage() {
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation('learning')

  const { data, loading } = useQuery<GetStudentTests>(GET_STUDENT_TESTS)

  const hasTests =
    data?.ListStudentTests?.existingTests?.length > 0 ||
    data?.ListStudentTests?.testsAvailableToStart?.length > 0

  const now = new Date()

  const existingTestsInProgress = data?.ListStudentTests?.existingTests?.filter(
    (existingTest) =>
      existingTest.finishedAt === null &&
      now < new Date(existingTest.mustFinishAt)
  )
  const testsAvailableToStartCurrent =
    data?.ListStudentTests?.testsAvailableToStart?.filter(
      (testAvailableToStart) => now < new Date(testAvailableToStart.finishAt)
    )

  const existingTestsExpired = data?.ListStudentTests?.existingTests?.filter(
    (existingTest) =>
      existingTest.finishedAt !== null ||
      now > new Date(existingTest.mustFinishAt)
  )

  return (
    <Levels>
      <Metadata title="Tests" description="Tests page" />
      <LoadingOverlay
        visible={loading}
        overlayProps={{ backgroundOpacity: 1 }}
      />
      <Grid gutter="lg" data-sel="test-instructions-page">
        {!isSmallScreen && (
          <Grid.Col span={{ md: 4, lg: 3 }} m={0} p={rem(10)}>
            <NavBar />
          </Grid.Col>
        )}
        <Grid.Col span={{ xs: 12, md: 8, lg: 6 }}>
          <Group pb={isSmallScreen ? rem(48) : rem(24)}>
            <BackNavigation backTo={routes.learningHomePage()} />
          </Group>
          <Group>
            <Title order={3}>{t('main.tests')}</Title>
          </Group>

          {hasTests ? (
            <Box>
              {(existingTestsInProgress?.length > 0 ||
                testsAvailableToStartCurrent?.length > 0) && (
                <>
                  <Text fz="xs" mt="xl" mb="md">
                    {t('test.tests-to-do-instructions')}
                  </Text>

                  <Stack gap="md">
                    {existingTestsInProgress?.map((existingTest) => (
                      <Anchor
                        component={Link}
                        to={routes.learningTestActivityPage({
                          studentTestId: existingTest.id,
                        })}
                        data-sel="student-link"
                        underline="never"
                        c="var(--text-color)"
                        key={existingTest.id}
                      >
                        <TestCard
                          status="inProgress"
                          title={existingTest.title}
                          subTitleOne={`${t('test.started-at')}: ${formatDate(existingTest.startedAt)} - ${formatTime(existingTest.startedAt)}`}
                          subTitleTwo={`${t('test.must-finish-at')}: ${formatDate(existingTest.mustFinishAt)} - ${formatTime(existingTest.mustFinishAt)}`}
                          mustFinishAt={existingTest.mustFinishAt}
                        />
                      </Anchor>
                    ))}

                    {testsAvailableToStartCurrent?.map(
                      (testAvailableToStartCurrent) => {
                        const disabled =
                          now < new Date(testAvailableToStartCurrent.startAt)

                        return (
                          <Anchor
                            component={disabled ? 'span' : Link}
                            to={routes.learningTestInstructionsPage({
                              testId: testAvailableToStartCurrent.id,
                            })}
                            data-sel="student-link"
                            underline="never"
                            c="var(--text-color)"
                            styles={{
                              root: {
                                cursor: disabled ? 'not-allowed' : undefined,
                              },
                            }}
                            key={testAvailableToStartCurrent.id}
                          >
                            <TestCard
                              status="toDo"
                              title={testAvailableToStartCurrent.title}
                              subTitleOne={`${t('test.available-at')}: ${formatDate(testAvailableToStartCurrent.startAt)} - ${formatTime(testAvailableToStartCurrent.startAt)}`}
                              subTitleTwo={`${t('test.deadline')}: ${formatDate(testAvailableToStartCurrent.finishAt)} - ${formatTime(testAvailableToStartCurrent.finishAt)}`}
                              disabled={disabled}
                            />
                          </Anchor>
                        )
                      }
                    )}
                  </Stack>
                </>
              )}

              {existingTestsExpired?.length > 0 && (
                <>
                  <SectionDivider title="Provas anteriores" />

                  <Stack gap="md">
                    {existingTestsExpired?.map((existingTest) => (
                      <TestCard
                        key={existingTest.id}
                        status="toBeCorrected"
                        title={existingTest.title}
                        subTitleOne={`${t('test.delivered-at')}: ${formatDate(existingTest.finishedAt || existingTest.mustFinishAt)} - ${formatTime(existingTest.finishedAt || existingTest.mustFinishAt)}`}
                      />
                    ))}
                  </Stack>
                </>
              )}
            </Box>
          ) : (
            <Box py={rem(48)}>
              <Text ta="center">
                Você não tem nenhuma prova disponível para iniciar.
              </Text>
            </Box>
          )}
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default TestsPage
