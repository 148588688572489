import { useRef, useEffect, useState } from 'react'

import {
  MediaPlayer as MediaPlayerVidstack,
  MediaProvider,
  type MediaPlayerInstance,
} from '@vidstack/react'
import {
  defaultLayoutIcons,
  DefaultAudioLayout,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default'
import store from 'store2'

import AudioMediaLoader from './AudioMediaLoader'
import { PT_BR } from './locales/pt-br'

import '@vidstack/react/player/styles/default/theme.css'
import '@vidstack/react/player/styles/default/layouts/video.css'
import '@vidstack/react/player/styles/default/layouts/audio.css'
import './MediaPlayer.css'

type Props = {
  currentMediaId?: number | null
  id?: number
  onMediaPlay?: (id: number) => void
  preload?: 'auto' | 'metadata' | 'none'
  title: string
  url: string
  load?: 'eager' | 'idle' | 'visible' | 'play' | 'custom'
  posterLoad?: 'eager' | 'idle' | 'visible' | 'custom'
}

const MediaPlayer = ({
  currentMediaId,
  id,
  onMediaPlay,
  preload = 'none',
  title,
  url,
  load = 'play',
  posterLoad = 'visible',
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const player = useRef<MediaPlayerInstance>(null)

  const currentLanguage = store('currentLocale') || 'pt_BR'
  const translations = currentLanguage === 'pt_BR' ? PT_BR : undefined

  const onPlay = () => {
    if (onMediaPlay) onMediaPlay(id)
  }

  const onLoadStart = () => {
    setIsLoading(true)
  }
  const onLoadedData = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if (id && currentMediaId && currentMediaId !== id && player.current) {
      player.current.pause()
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
      }
    }
  }, [id, currentMediaId])

  return (
    <MediaPlayerVidstack
      playsInline
      title={title}
      src={url}
      preload={preload}
      onPlay={onPlay}
      onLoadStart={onLoadStart}
      onLoadedData={onLoadedData}
      load={load}
      posterLoad={posterLoad}
      ref={player}
      data-sel="media-player"
    >
      <MediaProvider />
      <DefaultAudioLayout
        noAudioGain
        translations={translations}
        colorScheme="light"
        icons={defaultLayoutIcons}
      />
      <DefaultVideoLayout
        noAudioGain
        translations={translations}
        icons={defaultLayoutIcons}
      />
      <AudioMediaLoader isLoading={isLoading} />
    </MediaPlayerVidstack>
  )
}

export default MediaPlayer
