import { Badge, Card, Text, Title, rem, Flex, Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import TestCountdown from 'src/components/learning/TestCountdown/TestCountdown'

type Props = {
  title: string
  subTitleOne?: string
  subTitleTwo?: string
  mustFinishAt?: string
  disabled?: boolean
  status: 'inProgress' | 'toDo' | 'toBeCorrected'
}

const BADGE_LABEL = {
  inProgress: 'test.in-progress',
  toDo: 'test.to-do',
  toBeCorrected: 'test.to-be-corrected',
}

const BADGE_BG = {
  inProgress: 'yellow.0',
  toDo: 'cnaBlue.2',
  toBeCorrected: 'gray.2',
}

const BADGE_TEXT_COLOR = {
  inProgress: 'yellow.8',
  toDo: 'cnaBlue.7',
  toBeCorrected: 'gray.8',
}

export default function TestCard({
  title,
  subTitleOne,
  subTitleTwo,
  status,
  mustFinishAt,
  disabled,
}: Props) {
  const { t } = useTranslation('learning')

  return (
    <Card withBorder radius="md" opacity={disabled ? 0.5 : 1}>
      <Flex justify="space-between">
        <Box>
          <Title mb="md" order={5}>
            {title}
          </Title>
          {subTitleOne && (
            <Text mb={rem(4)} fz="sm">
              {subTitleOne}
            </Text>
          )}
          {subTitleTwo && <Text fz="sm">{subTitleTwo}</Text>}
        </Box>
        {status === 'inProgress' && mustFinishAt ? (
          <TestCountdown mustFinishAt={mustFinishAt} />
        ) : (
          <Badge
            px="sm"
            py={rem(14)}
            bg={BADGE_BG[status]}
            data-sel="activity-correction"
          >
            <Text size={rem(12)} c={BADGE_TEXT_COLOR[status]}>
              {t(BADGE_LABEL[status])}
            </Text>
          </Badge>
        )}
      </Flex>
    </Card>
  )
}
