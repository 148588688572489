import { IconProps } from '@tabler/icons-react'

import { TYPOLOGIES } from 'src/pages/Learning/Admin/ActivitiesListPage/ActivitiesListPage'

type Props = IconProps & { typology: string }

export default function ActivityTypologyIcon({ typology, ...props }: Props) {
  const data = TYPOLOGIES[typology]
  if (!data) return

  return React.createElement(data.icon, props)
}
