import { Box, Text, rem, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import ActivityToCorrect from 'web/src/components/learning/ActivityToCorrect/ActivityToCorrect'

import SectionDivider from 'src/components/shared/SectionDivider/SectionDivider'
import { ProcessAnswers } from 'src/helpers/answers'

type WritingListProps = {
  answers: ProcessAnswers
}

const WritingList = ({ answers }: WritingListProps) => {
  const { t } = useTranslation('learning')

  return (
    <Box>
      <Text
        size={rem(14)}
        pb={rem(16)}
        styles={{ root: { lineHeight: rem(18) } }}
      >
        {t('main.no-pending-activities-description')}
      </Text>
      {answers.map((answer, index) => (
        <Box key={index} data-sel="writing-item">
          <SectionDivider title={answer.unitTitle} />

          {answer.lessons?.length > 0 &&
            answer.lessons?.map((lesson, index) => (
              <Stack key={index} gap="md">
                {lesson.activities?.length > 0 &&
                  lesson.activities?.map((activity, index) => {
                    return (
                      <ActivityToCorrect
                        key={index}
                        activity={activity}
                        unitTitle={answer.unitTitle}
                        lessonTitle={lesson.lessonTitle}
                        to={`/learning/unit/${activity.unitId}/lessons/${activity.lessonId}/writing-activity/${activity.activityId}`}
                      />
                    )
                  })}
              </Stack>
            ))}
        </Box>
      ))}
    </Box>
  )
}

export default WritingList
