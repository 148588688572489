import { Box, Text, Badge, Group, Title, Anchor, rem } from '@mantine/core'
import { IconChevronRight, IconPencil } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { Link } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { formatDate, formatTime } from 'src/helpers/date'

type Props = {
  activity: {
    activityOrder: number
    teacherFeedbackAt: string | null
    unitId: number
    lessonId: number
    activityId: number
    latestUserAnswerAt: string | null
    latestAnswerHit: string | null
  }
  unitTitle: string
  lessonTitle: string
  to: string
}

export default function ActivityToCorrect({
  activity,
  unitTitle,
  lessonTitle,
  to,
}: Props) {
  const { currentUser } = useAuth()
  const isTeacher = currentUser?.type === 'TEACHER'

  const { t } = useTranslation('learning')

  const badgeProperties = {
    CORRECTED: {
      bg: 'green.1',
      c: 'green.9',
      title: 'main.corrected',
    },
    WAITING: {
      bg: 'gray.2',
      c: 'black',
      title: 'main.to-correct',
    },
  }

  const hasTeacherFeedback = !!activity.teacherFeedbackAt
  const badgeStatus = !hasTeacherFeedback ? 'WAITING' : 'CORRECTED'

  return (
    <Box
      p={rem(8)}
      data-sel={`writing-${activity.latestAnswerHit?.toLocaleLowerCase()}`}
    >
      <Anchor
        component={Link}
        to={to}
        data-sel="writing-link"
        underline="never"
        c="var(--text-color)"
      >
        <Group justify="space-between">
          <Box>
            <Text size={rem(12)} c="dark.3">
              {unitTitle}
            </Text>
            <Title data-sel="lesson-title" c="black" order={5}>
              {lessonTitle} - {t('common.activity')}{' '}
              {activity.activityOrder + 1}
            </Title>
            <Text size={rem(10)} c="dark.3" data-sel="sent-at">
              {hasTeacherFeedback
                ? t('main.correction-sent-at', {
                    date: formatDate(activity.teacherFeedbackAt),
                    time: formatTime(activity.teacherFeedbackAt),
                  })
                : t('main.answer-sent-at', {
                    date: formatDate(activity.latestUserAnswerAt),
                    time: formatTime(activity.latestUserAnswerAt),
                  })}
            </Text>
          </Box>
          <Group align="center">
            <Badge
              px="sm"
              py={rem(14)}
              {...badgeProperties[badgeStatus]}
              data-sel={`activity-${badgeStatus.toLowerCase()}`}
            >
              <Text size={rem(12)} {...badgeProperties[badgeStatus]}>
                {t(badgeProperties[badgeStatus].title)}
              </Text>
            </Badge>
            {isTeacher && badgeStatus === 'WAITING' ? (
              <IconPencil size={32} />
            ) : (
              <IconChevronRight size={32} />
            )}
          </Group>
        </Group>
      </Anchor>
    </Box>
  )
}
