import { useState, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Drawer,
  Container,
  TextInput,
  Title,
  Button,
  Center,
  Loader,
  rem,
} from '@mantine/core'

import { useMutation } from '@redwoodjs/web'

import ActivityMediaUploadZone from 'src/components/learning/Admin/ActivityMediaUploadZone/ActivityMediaUploadZone'
import { notifications } from 'src/components/shared/Notifications'
import {
  GET_RESOURCE,
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
} from 'src/graphql/learning/learning-admin'

type EditResourceProps = {
  onClose: () => void
  onRefetch: () => void
  opened: boolean
  id?: number
}

const EditResource = ({
  onClose,
  onRefetch,
  opened,
  id,
}: EditResourceProps) => {
  const [resourceData, setResourceData] = useState({
    name: '',
    media: [],
  })

  const [updateResource, { loading: updateResourceLoading }] =
    useMutation(UPDATE_RESOURCE)

  const [createResource, { loading: createResourceLoading }] =
    useMutation(CREATE_RESOURCE)

  const [getResource, { loading }] = useLazyQuery(GET_RESOURCE, {
    onCompleted: (data) => {
      if (data && data.FindResource) {
        const { FindResource } = data
        const { name, media } = FindResource
        setResourceData({
          name,
          media: [{ type: media[0]?.type, url: media[0]?.url }],
        })
      }
    },
    onError: () => {
      notifications.error({
        title: 'Erro ao carregar o resource',
        message: 'Recarregue a página e tente novamente.',
      })
    },
  })

  const onCreateResource = async () => {
    try {
      await createResource({
        variables: {
          input: { name: resourceData.name, media: resourceData.media },
        },
      })
      notifications.success({
        title: 'Resource criado com sucesso',
        message: 'O resource foi criado com sucesso.',
      })
      onClose()
      onRefetch()
      setResourceData({ name: '', media: [] })
    } catch {
      notifications.error({
        title: 'Erro ao criar o resource',
        message: 'Recarregue a página e tente novamente.',
      })
    }
  }

  const onUpdateResource = async () => {
    try {
      await updateResource({
        variables: {
          id,
          input: {
            name: resourceData.name,
            media: resourceData.media,
          },
        },
      })
      notifications.success({
        title: 'Resource atualizado com sucesso',
        message: 'O resource foi atualizado com sucesso.',
      })
      onClose()
      onRefetch()
      setResourceData({ name: '', media: [] })
    } catch {
      notifications.error({
        title: 'Erro ao atualizar o resource',
        message: 'Recarregue a página e tente novamente.',
      })
    }
  }

  const onSaveResource = async () => {
    if (id) {
      await onUpdateResource()
    } else {
      await onCreateResource()
    }
  }

  const onRemove = () => {
    setResourceData({
      ...resourceData,
      media: [],
    })
  }

  const onDrop = (url, mediaType) => {
    setResourceData({
      ...resourceData,
      media: [{ type: mediaType, url }],
    })
  }

  useEffect(() => {
    if (id) {
      getResource({ variables: { id } })
    } else {
      setResourceData({ name: '', media: [] })
    }
  }, [id, getResource, setResourceData])

  return (
    <Drawer
      position="bottom"
      opened={opened}
      onClose={onClose}
      styles={{ content: { height: rem(650) } }}
    >
      <Container maw={rem(600)} h="100%">
        {loading ? (
          <Center h="100%">
            <Loader />
          </Center>
        ) : (
          <>
            <Title order={3} pb={rem(16)}>
              {id ? `Editando resource #${id}` : 'Novo resource'}
            </Title>
            <Title order={6} pb={rem(8)}>
              Nome
            </Title>
            <TextInput
              value={resourceData?.name}
              onChange={(e) => {
                setResourceData({
                  ...resourceData,
                  name: e.currentTarget.value,
                })
              }}
            />
            <Title pt={rem(16)} pb={rem(8)} order={6}>
              Mídia
            </Title>
            <ActivityMediaUploadZone
              media={resourceData.media}
              text="Adicione a mídia"
              onDrop={(url, mediaType) => onDrop(url, mediaType)}
              onRemove={onRemove}
            />
            <Button
              disabled={!resourceData.name || !resourceData.media.length}
              mt={rem(16)}
              onClick={onSaveResource}
              w={rem(100)}
            >
              {updateResourceLoading || createResourceLoading ? (
                <Loader color="white" size="sm" />
              ) : (
                'Salvar'
              )}
            </Button>
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default EditResource
