import { ActivityAnswer } from 'types/graphql'

type ProcessedLesson = ActivityAnswer & {
  lessonOrder: number
  activities: Array<{
    activityId: number
    activityOrder: number
    lessonId: number
    unitId: number
    latestAnswerHit: string
    latestUserAnswerAt: string | null
    teacherFeedbackAt: string | null
  }>
}

export const processAnswers = (answers: ActivityAnswer[]) => {
  const grouped = answers.reduce((acc, a) => {
    const unit = acc[a.unitId] || (acc[a.unitId] = { ...a, lessons: {} })
    const lesson =
      unit.lessons[a.lessonId] ||
      (unit.lessons[a.lessonId] = {
        ...a,
        activities: [],
        lessonOrder: a.lessonOrder,
      })
    lesson.activities.push({
      activityId: a.activityId,
      activityOrder: a.activityOrder,
      latestAnswerHit: a.latestAnswerHit,
      teacherFeedbackAt: a.teacherFeedbackAt,
      latestUserAnswerAt: a.latestUserAnswerAt,
      unitId: a.unitId,
      lessonId: a.lessonId,
    })
    return acc
  }, {})

  return Object.values(
    grouped as Record<
      string,
      ActivityAnswer & {
        lessons: Record<string, ProcessedLesson>
      }
    >
  )
    .sort((a, b) => a.unitOrder - b.unitOrder)
    .map((unit) => ({
      ...unit,
      lessons: Object.values(unit.lessons)
        .filter((lesson) =>
          lesson.activities.some(
            (activity) => activity.latestAnswerHit !== null
          )
        )
        .sort((a, b) => (a.lessonOrder ?? 0) - (b.lessonOrder ?? 0))
        .map((lesson) => ({
          ...lesson,
          activities: lesson.activities
            .filter((activity) => activity.latestAnswerHit !== null)
            .sort((a, b) => (a.activityOrder ?? 0) - (b.activityOrder ?? 0)),
        })),
    }))
}

export type ProcessAnswers = ReturnType<typeof processAnswers>
