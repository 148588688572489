import { useEffect, useState, useRef } from 'react'

import { Drawer, Menu, rem } from '@mantine/core'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

type Props = {
  children: React.ReactNode
  target: React.ReactNode
  toogle: () => void
  opened: boolean
}

export default function DropdownMenu({
  children,
  target,
  toogle,
  opened,
}: Props) {
  const isSmallScreen = useIsSmallScreen()

  const [contentHeight, setContentHeight] = useState(0)
  const menuContentRef = useRef(null)

  useEffect(() => {
    const updateHeight = () => {
      if (menuContentRef.current) {
        setContentHeight(menuContentRef.current.clientHeight)
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    if (opened && menuContentRef.current) {
      const observer = new MutationObserver(updateHeight)
      const config = { childList: true, subtree: true, attributes: true }
      observer.observe(menuContentRef.current, config)

      return () => {
        observer.disconnect()
        window.removeEventListener('resize', updateHeight)
      }
    }
  }, [opened])

  return (
    <>
      <Menu
        width={isSmallScreen ? '100vw' : '374px'}
        radius={isSmallScreen ? 0 : rem(8)}
        position="bottom-end"
        opened={opened}
        offset={24}
        onChange={toogle}
        styles={
          isSmallScreen
            ? {
                dropdown: { left: 0 },
              }
            : {}
        }
      >
        <Menu.Target>{target}</Menu.Target>

        <Menu.Dropdown ref={menuContentRef}>{children}</Menu.Dropdown>
      </Menu>

      <Drawer
        opened={isSmallScreen && opened}
        onClose={toogle}
        position="top"
        styles={{
          inner: { top: '96px' },
          overlay: { top: '96px' },
          content: { height: contentHeight },
        }}
        withCloseButton={false}
        transitionProps={{
          duration: 0,
        }}
      ></Drawer>
    </>
  )
}
