import { Avatar, Menu, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconLogout } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { useAuth } from 'src/auth'
import DropdownMenu from 'src/components/shared/DropdownMenu'

type User = {
  name: string
  picture?: string
}

export default function TeacherProfileMenu() {
  const { logOut, isAuthenticated, currentUser } = useAuth()
  const { t } = useTranslation('learning')
  const [opened, { open, close }] = useDisclosure(false)

  const { name, picture } = currentUser ?? ({} as User)

  const words = isAuthenticated ? name?.split(' ') : undefined

  const initials = words
    ? `${words[0].charAt(0)} ${
        words.length > 1 ? words[words.length - 1].charAt(0) : ''
      }`
    : ''

  return (
    <>
      {isAuthenticated && (
        <DropdownMenu
          target={
            <Avatar
              data-sel="profile-menu"
              component="button"
              src={picture || null}
              alt="Profile picture"
              size={48}
              style={{ border: 'none', cursor: 'pointer' }}
              onClick={opened ? close : open}
              bg={picture ? 'transparent' : 'cnaRed.6'}
            >
              {!picture ? (
                <Text c="white" w="500">
                  {initials.toUpperCase()}
                </Text>
              ) : (
                ''
              )}
            </Avatar>
          }
          toogle={opened ? close : open}
          opened={opened}
        >
          <Menu.Dropdown data-sel="profile-menu-items">
            <Menu.Item
              py="sm"
              color="red"
              leftSection={<IconLogout size={16} />}
              onClick={logOut}
              data-sel="logout"
            >
              {t('common.logout')}
            </Menu.Item>
          </Menu.Dropdown>
        </DropdownMenu>
      )}
    </>
  )
}
