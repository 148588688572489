import { useContext } from 'react'

import { Paper, Title, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ActivityAnswerField } from 'types/graphql'

import { TypologyEnum } from 'src/enums/learning/activity/TypologyEnum'
import { answerFieldsKeepCorrectChoices } from 'src/helpers/activity'
import { ActivityContext } from 'src/providers/learning/activity/ActivityProvider'

import CorrectCombination from './components/CorrectCombination/CorrectCombination'
import CorrectFillInTheBlanks from './components/CorrectFillInTheBlanks/CorrectFillInTheBlanks'
import CorrectMultipleChoice from './components/CorrectMultipleChoice/CorrectMultipleChoice'
import CorrectSequencing from './components/CorrectSequencing/CorrectSequencing'

export type CorrectAnswerChildrenAnswerProps = {
  answers: ActivityAnswerField[]
}

const CorrectAnswer = () => {
  const { activity } = useContext(ActivityContext)
  const { t } = useTranslation('learning')

  const { answerFields, typology } = activity

  const answers = answerFieldsKeepCorrectChoices(
    answerFields as ActivityAnswerField[]
  )

  const correctAnswerComponentMap: Partial<{
    [key in TypologyEnum]: React.ElementType
  }> = {
    [TypologyEnum.SEQUENCING]: CorrectSequencing,
    [TypologyEnum.MULTIPLE_CHOICE]: CorrectMultipleChoice,
    [TypologyEnum.FILL_IN_THE_BLANKS]: CorrectFillInTheBlanks,
    [TypologyEnum.COMBINATION]: CorrectCombination,
  }

  const CorrectAnswerComponent =
    correctAnswerComponentMap[typology] || (() => null)

  return (
    <Paper
      p="md"
      mb="md"
      radius={rem(12)}
      bg="yellow.1"
      data-sel="correct-answer"
    >
      <Title order={6}>{t('main.answer')}</Title>
      <CorrectAnswerComponent answers={answers} />
    </Paper>
  )
}

export default CorrectAnswer
