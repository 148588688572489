import { Divider, Text, rem, Flex, FlexProps } from '@mantine/core'

type Props = FlexProps & {
  title: string
}

export default function SectionDivider({ title, ...rest }: Props) {
  return (
    <Flex align="center" my="xl" {...rest} h="lg">
      <Divider w="100%" color="gray.4" />
      <Text
        style={{ flexGrow: 1, flexShrink: 0 }}
        size={rem(12)}
        px={rem(8)}
        data-sel="unit-title"
        c="cnaRed.6"
      >
        {title}
      </Text>
      <Divider w="100%" color="gray.4" />
    </Flex>
  )
}
