import { Group, Image, rem, Container } from '@mantine/core'
import { MenuItem } from 'types/menuItem'

import { Link } from '@redwoodjs/router'

import MainMenu from 'src/components/shared/MainMenu/MainMenu'

type HeaderProps = {
  logo?: string
  logoTo?: string
  menu: MenuItem[]
  children?: React.ReactNode
}

const Header = ({
  logo = '/cna-logo-minimal.svg',
  menu,
  children,
  logoTo = '/learning/dashboard',
}: HeaderProps) => {
  return (
    <Container fluid bg="white" p={0} data-sel="header">
      <Container maw={rem(1440)}>
        <Group justify="space-between" h={rem(96)}>
          <Group>
            <Link to={logoTo} data-sel="header-logo">
              <Image src={logo} alt="CNA Logo" height={rem(24)} />
            </Link>
            <Container
              ml={rem(30)}
              visibleFrom="md"
              data-sel="header-main-menu"
            >
              {menu.map((item, index) => (
                <MainMenu menuItem={item} key={index} />
              ))}
            </Container>
          </Group>
          <Group align="center" justify="space-between" gap={rem(30)}>
            <Group gap="sm">{children}</Group>
          </Group>
        </Group>
      </Container>
    </Container>
  )
}

export default Header
