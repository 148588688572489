import React from 'react'

import { Grid, Card, rem } from '@mantine/core'
import store from 'store2'
import { LevelUnit } from 'types/levelUnit'

import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'

import { Unit } from 'src/components/learning/Unit/Unit'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import NoUnitsFound from '../NoUnitsFound/NoUnitsFound'

const Units = () => {
  const { levelUnits } = useLevel()

  const goToUnit = (unit: LevelUnit) => {
    const { id, title } = unit
    store('unit', JSON.stringify({ id, title }))
    navigate(routes.learningUnitLessons())
  }

  return (
    <>
      {!levelUnits?.length && (
        <Card withBorder radius="md" mt="lg">
          <NoUnitsFound />
        </Card>
      )}
      <Grid
        gutter="lg"
        pt={{ base: 'xl', md: 0 }}
        pb={rem(5)}
        data-sel="units-list"
      >
        {levelUnits?.map((unit, index) => {
          return (
            <Grid.Col key={index} span={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <Unit unit={unit} onClick={goToUnit} />
            </Grid.Col>
          )
        })}
      </Grid>
    </>
  )
}

export default Units
