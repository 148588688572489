import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Title, Grid, rem, Center, Loader } from '@mantine/core'

import { routes } from '@redwoodjs/router'

import Levels from 'src/components/learning/Levels'
import NavBar from 'src/components/learning/NavBar/NavBar'
import TopMainSection from 'src/components/learning/TopMainSection/TopMainSection'
import { GET_WRITING_ACTIVITIES } from 'src/graphql/learning/learning'
import { processAnswers } from 'src/helpers/answers'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import WritingList from './components/WritingList/WritingList'
import WritingListPlaceholder from './components/WritingListPlaceholder/WritingListPlaceholder'

const WritingActivitiesPage = () => {
  const [getWritingActivities, { data, loading }] = useLazyQuery(
    GET_WRITING_ACTIVITIES
  )
  const { currentLevel } = useLevel()
  const isSmallScreen = useIsSmallScreen()
  let answers = []

  useEffect(() => {
    const { id } = currentLevel

    if (!id) return

    getWritingActivities({
      variables: {
        levelId: id,
      },
    })
  }, [currentLevel, getWritingActivities])

  const { WritingAnswers } = data || {}

  if (WritingAnswers) {
    answers = processAnswers(WritingAnswers?.answers)
  }

  const hasAnswers =
    answers.length > 0 && answers?.find((answer) => answer.lessons.length > 0)
  return (
    <Levels>
      <Grid gutter="lg" data-sel="writing-list">
        {!isSmallScreen && (
          <Grid.Col span={{ md: 4, lg: 3 }} m={0} p={rem(10)}>
            <NavBar />
          </Grid.Col>
        )}
        <Grid.Col span={{ xs: 12, md: 8, lg: 9 }}>
          <TopMainSection navigation={routes.learningHomePage()} />
          <Title order={4} pb={rem(20)}>
            Writing
          </Title>
          {loading ? (
            <Center h="calc(100% - 125px)">
              <Loader />
            </Center>
          ) : (
            <>
              {hasAnswers ? (
                <WritingList answers={answers} />
              ) : (
                <WritingListPlaceholder
                  linkLabel="main.go-to-activities"
                  goTo={routes.learningHomePage()}
                />
              )}
            </>
          )}
        </Grid.Col>
      </Grid>
    </Levels>
  )
}

export default WritingActivitiesPage
