import { Card, Title, NavLink, Box, rem } from '@mantine/core'
import {
  IconChevronRight,
  IconMessageCircle2,
  IconFolders,
  IconWriting,
  IconClipboardData,
} from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from 'types/menuItem'

import { Link, useLocation } from '@redwoodjs/router'

import Media from 'src/components/learning/Media'
import { externalLinks } from 'src/helpers/externalLinks'
import { EXTERNAL_URL_REGEX } from 'src/helpers/url'
import { useFirstMedia } from 'src/hooks/shared/media'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

import CurrentLevel from '../CurrentLevel/CurrentLevel'

import styles from './NavBar.module.css'

export const items: MenuItem[] = [
  {
    label: 'common.resources',
    route: '/learning/resources',
    icon: <IconFolders stroke={1.75} />,
  },
  {
    label: 'main.writing',
    route: '/learning/activities/writing',
    icon: <IconWriting stroke={1.75} />,
  },
  {
    label: 'common.online-teacher',
    route: externalLinks.onlineTeacher,
    icon: <IconMessageCircle2 stroke={1.75} />,
  },
  {
    label: 'main.tests',
    route: '/learning/tests',
    icon: <IconClipboardData stroke={1.75} />,
  },
]

const NavBar = () => {
  const location = useLocation()
  const { t } = useTranslation('learning')
  const { currentLevel } = useLevel()
  const { coverMedia } = currentLevel

  const { url, type } = useFirstMedia(coverMedia)

  return (
    <Box data-sel="nav-bar">
      <Card mr={rem(20)} p="md" w="100%" withBorder radius="md">
        {type && url && <Media type={type} url={url} py={0} radius={rem(12)} />}
        <Box pt="md">
          <CurrentLevel />
        </Box>
        <Box visibleFrom="md" data-sel="nav-bar-links">
          {items?.map((item, index) => {
            const isExternalUrl = EXTERNAL_URL_REGEX.test(item.route)
            return (
              <Card p={0} mt={rem(20)} radius={rem(8)} key={index}>
                <NavLink
                  className={styles.NavBarItem}
                  active={location.pathname === item.route}
                  fw={500}
                  component={isExternalUrl ? 'a' : Link}
                  to={item.route}
                  href={isExternalUrl ? item.route : undefined}
                  target={isExternalUrl ? '_blank' : undefined}
                  label={<Title order={5}>{t(item.label)}</Title>}
                  leftSection={item.icon ? item.icon : <></>}
                  rightSection={<IconChevronRight size={24} stroke={1.75} />}
                  bg="gray.0"
                  c="cnaRed.6"
                  data-sel="nav-bar-link"
                />
              </Card>
            )
          })}
        </Box>
      </Card>
    </Box>
  )
}

export default NavBar
