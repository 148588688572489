import {
  Image,
  Center,
  Group,
  Box,
  Anchor,
  Title,
  Text,
  Paper,
  LoadingOverlay,
  Modal,
  Button,
  rem,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconAlertOctagon } from '@tabler/icons-react'

import { Link, navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { useQuery, useMutation } from '@redwoodjs/web'

import ActivityTypologyIcon from 'src/components/learning/ActivityTypologyIcon'
import ContainerWrap from 'src/components/shared/ContainerWrap'
import { notifications } from 'src/components/shared/Notifications'
import { TypologyEnum } from 'src/enums/learning/activity/TypologyEnum'
import {
  GET_TEACHER_STUDENT_ACTIVITIES_GRADE,
  FINISH_TEACHER_STUDENT_GRADE,
  GET_TEACHER_STUDENT_FINAL_GRADE,
} from 'src/graphql/learning/teacher'
import { useActivityTitle } from 'src/hooks/learning/activity'

type TeacherFinishStudentGradePageProps = {
  studentTestId: number
  studentId: number
  classId: number
}

const ActivityTitle = ({ typology }: { typology: TypologyEnum }) => {
  const title = useActivityTitle(typology)
  return <Text>{title}</Text>
}

const TeacherFinishStudentGradePage = ({
  studentTestId,
  studentId,
  classId,
}: TeacherFinishStudentGradePageProps) => {
  const [opened, { open, close }] = useDisclosure(false)

  const { data: finalGradeData, loading: loadingStudentFinalGrade } = useQuery(
    GET_TEACHER_STUDENT_FINAL_GRADE,
    {
      variables: {
        studentTestId,
      },
    }
  )

  const [finishTeacherGrade, { loading: finishingGrade }] = useMutation(
    FINISH_TEACHER_STUDENT_GRADE,
    {
      variables: {
        studentTestId,
      },
      onError: (error) => {
        const message =
          error?.message ||
          'Erro ao salavar a correção, recarregue a página e tente novamente.'

        notifications.error({
          title: 'Erro ao salvar correção',
          message,
        })
      },
      onCompleted: (data) => {
        const { gradedAt, grade } = data.FinishGradingTest
        navigateToSummaryPage(grade, gradedAt)
      },
    }
  )

  const navigateToSummaryPage = (grade: number, gradedAt: string) => {
    navigate(
      routes.learningTeacherStudentGradeSummaryPage({
        studentId,
        grade,
        gradedAt: gradedAt.split('T')[0],
        classId,
      })
    )
  }

  const { loading, data } = useQuery(GET_TEACHER_STUDENT_ACTIVITIES_GRADE, {
    variables: {
      studentTestId,
    },

    onError: (error) => {
      const message =
        error?.message ||
        'Erro ao finalizar prova, recarregue a página e tente novamente.'

      notifications.error({
        title: 'Erro',
        message,
      })
    },
  })

  const { ListTestActivitiesForGrading } = data || {}

  const totalGrade =
    ListTestActivitiesForGrading?.reduce((sum, { grade }) => sum + grade, 0) ??
    0

  const onFinishGrade = () => {
    close()
    finishTeacherGrade()
  }

  const { FindStudentGrade } = finalGradeData || {}
  const { gradedAt, grade } = FindStudentGrade || {}
  const isLoading = loading || finishingGrade || loadingStudentFinalGrade

  return (
    <ContainerWrap>
      {isLoading ? (
        <LoadingOverlay visible={true} />
      ) : (
        <>
          <Center>
            <Box>
              <Center>
                <Image
                  src="/learning/grade-test.png"
                  alt="Teacher finish grade"
                  w={rem(200)}
                />
              </Center>
              <Title ta="center" pt={rem(16)} pb={rem(24)} order={3}>
                Resumo da correção
              </Title>
              <Text pb={rem(16)}>
                Verifique se todas as atividades foram corrigidas.
              </Text>
              <Text pb={rem(16)}>
                Clique em qualquer atividade para abrir e revisar.
              </Text>
              <Text pb={rem(16)}>
                Ao selecionar “Encerrar correção”, o sistema calculará a nota
                final. Registre a nota final no CNA Box.
              </Text>
              {ListTestActivitiesForGrading?.map((activity) => (
                <Box key={activity.id} my={rem(20)}>
                  <Anchor
                    key={activity.id}
                    c="black"
                    underline="never"
                    component={Link}
                    to={routes.learningTeacherStudentGradePageActivity({
                      studentId,
                      classId,
                      studentTestId,
                      activityId: activity.id,
                    })}
                  >
                    <Group justify="space-between">
                      <Group>
                        <ActivityTypologyIcon
                          typology={activity.typology}
                          size={16}
                        />
                        <ActivityTitle typology={activity.typology} />
                      </Group>
                      <Paper
                        radius={rem(48)}
                        w={rem(120)}
                        bg="green.1"
                        c="green.9"
                        py="md"
                      >
                        <Text ta="center" size={rem(12)}>
                          NOTA {activity.grade?.toFixed(2)}
                        </Text>
                      </Paper>
                    </Group>
                  </Anchor>
                </Box>
              ))}
              <Title ta="right" order={5} pt={rem(14)} pb={rem(24)}>
                Nota final: {totalGrade?.toFixed(2)}
              </Title>
            </Box>
          </Center>

          <Modal opened={opened} onClose={close}>
            <Center>
              <IconAlertOctagon
                size={32}
                color="var(--mantine-color-cnaRed-6)"
              />
            </Center>
            <Text ta="center" size="lg" fw="500" py={rem(16)}>
              Antes de encerrar...
            </Text>
            <Text ta="center" pb={rem(24)}>
              Verifique se todas as notas foram atribuídas. Uma vez encerrada a
              correção, não será possível editar as notas.
            </Text>
            <Text ta="center" pb={rem(24)}>
              Tem certeza que deseja encerrar a correção?
            </Text>
            <Group justify="space-between">
              <Button size="lg" onClick={onFinishGrade}>
                Sim, encerrar
              </Button>
              <Button
                c="black"
                size="lg"
                variant="outline"
                onClick={close}
                style={{ borderColor: 'black' }}
              >
                Ainda não
              </Button>
            </Group>
          </Modal>
          <Center pb={rem(24)}>
            <Button
              fw="500"
              size="lg"
              onClick={() => {
                if (!gradedAt) {
                  open()
                } else {
                  navigateToSummaryPage(grade, gradedAt)
                }
              }}
            >
              {gradedAt ? 'Continuar' : 'Encerrar correção'}
            </Button>
          </Center>
        </>
      )}
    </ContainerWrap>
  )
}

export default TeacherFinishStudentGradePage
