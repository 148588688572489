import {
  Activity,
  ActivityTest,
  ActivityAnswerField,
  UserAnswer,
  UserAnswerTest,
} from 'types/graphql'

import { AnswerHitEnum } from 'src/enums/learning/activity/AnswerHitEnum'

const orderActivities = (activities: Activity[], lessonId: number) => {
  return [...activities]?.sort((a, b) => {
    const moduleA = a?.modules?.find((module) => module?.moduleId === lessonId)
    const moduleB = b?.modules?.find((module) => module?.moduleId === lessonId)

    return moduleA?.order - moduleB?.order
  })
}

const orderActivityAnswerFields = (answerFields: ActivityAnswerField[]) => {
  const orderedFields = answerFields
    .map((item) => {
      const choices = item?.choices ? [...item.choices] : []

      return {
        ...item,
        choices: choices.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
        coverMedia:
          item?.coverMedia ||
          item?.choices?.find((choice) => choice.coverMedia)?.coverMedia,
        text: item?.text || item?.choices?.find((choice) => choice.text)?.text,
      }
    })
    .sort((a, b) => (a?.order || 0) - (b?.order || 0))

  return orderedFields
}

const answerFieldsKeepCorrectChoices = (
  answerFields: ActivityAnswerField[] = []
) => {
  return answerFields.map((answer) => {
    const choices = answer?.choices || []
    const correctOrderedChoices = choices.filter((choice) => choice.isCorrect)

    return {
      ...answer,
      choices: correctOrderedChoices,
    }
  })
}

const isActivityPending = (activity: Activity) => {
  return activity?.answerFields?.some((answerField) => {
    return (
      answerField?.answers?.length === 0 ||
      answerField?.answers?.some(
        (answer) =>
          answer?.hit === AnswerHitEnum.MISS && activity.typology !== 'WRITING'
      )
    )
  })
}

const isActivityDone = (activity: Activity) => {
  return activity?.answerFields?.every((answerField) => {
    return (
      answerField?.answers?.length > 0 &&
      answerField?.answers?.every(
        (answer) => answer?.hit === AnswerHitEnum.DONE
      )
    )
  })
}

const hasAnswers = (activity: Activity | ActivityTest) => {
  return activity?.answerFields?.some(
    (answerField) => answerField?.answers?.length > 0
  )
}

const formatAnswerFieldsInput = (
  activity: Activity | ActivityTest,
  answer: unknown[]
) => {
  return {
    answerFields: activity?.answerFields.map(
      (answerField, answerFieldIndex) => ({
        answerFieldId: answerField.id,
        answers: [
          ...(activity?.typology === 'WRITING'
            ? [
                {
                  discursive: answer[0],
                },
              ]
            : []),
          ...(activity?.typology === 'FILL_IN_THE_BLANKS' ||
          activity?.typology === 'SEQUENCING'
            ? answer.map((answerItem: string, answerIndex) => ({
                choiceId: parseInt(answerItem),
                order: answerField.choices[answerIndex].order,
              }))
            : []),
          ...(activity?.typology === 'COMBINATION'
            ? [
                {
                  choiceId:
                    (answer[answerFieldIndex] as { id: number; order: number })
                      ?.id || null,
                  order:
                    (answer[answerFieldIndex] as { id: number; order: number })
                      ?.order !== undefined
                      ? (
                          answer[answerFieldIndex] as {
                            id: number
                            order: number
                          }
                        ).order
                      : null,
                },
              ]
            : []),
          ...(activity?.typology === 'MULTIPLE_CHOICE'
            ? answer.map((answerItem: string) => ({
                choiceId: parseInt(answerItem),
              }))
            : []),
        ],
      })
    ),
  }
}

const addAnswerToActivity = (
  prevActivity: Activity | ActivityTest,
  answers: UserAnswer[] | UserAnswerTest[]
) => {
  return {
    ...prevActivity,
    answered: true,
    answerFields: prevActivity.answerFields.map((answerField) => {
      const newAnswers = answers?.filter(
        (answer) => answer.answerFieldId === answerField.id
      )

      return {
        ...answerField,
        answers: newAnswers || [],
      }
    }),
  }
}

export {
  answerFieldsKeepCorrectChoices,
  orderActivities,
  orderActivityAnswerFields,
  isActivityPending,
  isActivityDone,
  hasAnswers,
  formatAnswerFieldsInput,
  addAnswerToActivity,
}
