import React, { createContext, useContext, ReactNode, useState } from 'react'

import { Module, Activity, ActivityTest } from 'types/graphql'

type ActivityOrActivityTest = Activity | ActivityTest

interface ActivityState {
  activity: ActivityOrActivityTest
  activities: ActivityOrActivityTest[]
  activityUnit: Module
  setActivity: React.Dispatch<React.SetStateAction<ActivityOrActivityTest>>
  setActivities: React.Dispatch<React.SetStateAction<ActivityOrActivityTest[]>>
  setActivityUnit: React.Dispatch<React.SetStateAction<Module>>
}

const ActivityContext = createContext<ActivityState | undefined>(undefined)

const useActivity = (): ActivityState => {
  const context = useContext(ActivityContext)

  if (!context) {
    throw new Error('useActivity must be used within an ActivityProvider')
  }

  return {
    activity: context.activity,
    activities: context.activities,
    activityUnit: context.activityUnit,
    setActivity: context.setActivity,
    setActivities: context.setActivities,
    setActivityUnit: context.setActivityUnit,
  }
}

type ActivityProviderProps = {
  children: ReactNode
}

const ActivityProvider = ({ children }: ActivityProviderProps) => {
  const [activity, setActivity] = useState<ActivityOrActivityTest>()
  const [activities, setActivities] = useState<ActivityOrActivityTest[]>([])
  const [activityUnit, setActivityUnit] = useState<Module>()

  const value = {
    activity,
    activityUnit,
    activities,
    setActivity,
    setActivities,
    setActivityUnit,
  }

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  )
}

export { ActivityProvider, useActivity, ActivityContext }
