import { useQuery } from '@apollo/client'
import {
  Anchor,
  Avatar,
  Badge,
  Box,
  Card,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  Title,
  rem,
} from '@mantine/core'
import { IconChevronRight, IconWriting } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Maybe, TeacherSchoolClassStudent } from 'types/graphql'

import { Link, useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import BackNavigation from 'src/components/learning/BackNavigation/BackNavigation'
import ContainerWrap from 'src/components/shared/ContainerWrap/ContainerWrap'
import { notifications } from 'src/components/shared/Notifications'
import { GET_TEACHER_SCHOOL_CLASSES_STUDENTS } from 'src/graphql/learning/teacher'
import { getInitials } from 'src/helpers/user'
import { useIsSmallScreen } from 'src/hooks/shared/screen'

import ClassSwitcher from './components/ClassSwitcher/ClassSwitcher'

type FilterTeacherSchoolClassStudents = {
  FilterTeacherSchoolClassStudents?: Maybe<TeacherSchoolClassStudent[]>
}

const ClassStudentsPage = () => {
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation('learning')
  const { classId } = useParams()

  const { data: students, loading } =
    useQuery<FilterTeacherSchoolClassStudents>(
      GET_TEACHER_SCHOOL_CLASSES_STUDENTS,
      {
        variables: {
          schoolClassId: classId,
        },
        onError: (_) => {
          notifications.error({
            title: t('main.loading-error'),
            message: '',
          })
        },
      }
    )

  return (
    <>
      <Metadata title="Students" description="Class Students page" />
      <LoadingOverlay
        visible={loading}
        overlayProps={{ backgroundOpacity: 1 }}
      />
      <ContainerWrap>
        <Grid data-sel="class-students">
          {!isSmallScreen && (
            <Grid.Col span={{ md: 4, lg: 3 }} m={0}>
              <Paper withBorder radius="md" p={rem(24)}>
                <ClassSwitcher />
              </Paper>
            </Grid.Col>
          )}
          <Grid.Col span={{ xs: 12, md: 6 }}>
            <BackNavigation backTo="/learning/classes" label="common.back" />
            <Title order={4} pt={rem(20)} data-sel="class-students-title">
              {t('teacher.my-students')}
            </Title>
            <Box mt={rem(30)}>
              {isSmallScreen && (
                <Box pb={rem(40)}>
                  <ClassSwitcher />
                </Box>
              )}
            </Box>
            {students?.FilterTeacherSchoolClassStudents.length ? (
              students?.FilterTeacherSchoolClassStudents.map((student) => (
                <Box key={student.studentId} pb={rem(40)}>
                  <Anchor
                    component={Link}
                    to={`/learning/classes/${classId}/student/${student.studentId}`}
                    data-sel="student-link"
                    underline="never"
                    c="var(--text-color)"
                  >
                    <Group justify="space-between">
                      <Group>
                        <Avatar
                          data-sel="student-avatar"
                          src={null}
                          alt="Student picture"
                          size={40}
                          bg="cnaRed.6"
                          styles={{
                            root: {
                              '--avatar-color': 'white',
                            },
                            placeholder: {
                              fontWeight: 500,
                            },
                          }}
                        >
                          {getInitials(student.studentName)}
                        </Avatar>
                        <Box>
                          <Text
                            c="black"
                            size={rem(14)}
                            data-sel="student-name"
                            truncate="end"
                            maw="calc(100vw - 200px)"
                          >
                            {student.studentName}
                          </Text>
                          <Text
                            c="dark.3"
                            size={rem(12)}
                            pt={rem(8)}
                            data-sel="student-level"
                          >
                            {student.levelName}
                          </Text>
                        </Box>
                      </Group>
                      <Group>
                        {student.pendingCorrectionActivities &&
                          student.pendingCorrectionActivities > 0 && (
                            <Badge
                              data-sel="activity-count"
                              bg="cnaRed.0"
                              px={rem(12)}
                              py={rem(18)}
                            >
                              <Group gap={rem(4)}>
                                <IconWriting
                                  size={16}
                                  color="var(--mantine-color-cnaRed-7)"
                                />
                                <Text c="cnaRed.7" size={rem(12)}>
                                  {student.pendingCorrectionActivities}
                                </Text>
                              </Group>
                            </Badge>
                          )}
                        <IconChevronRight size={24} />
                      </Group>
                    </Group>
                  </Anchor>
                </Box>
              ))
            ) : (
              <Card>
                <Text>
                  Nenhum aluno encontrado para esta turma. Os alunos aparecerão
                  aqui quando estiverem disponíveis.
                </Text>
              </Card>
            )}
          </Grid.Col>
        </Grid>
      </ContainerWrap>
    </>
  )
}

export default ClassStudentsPage
