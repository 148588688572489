import { UnstyledButton, Group, Text } from '@mantine/core'
import { rem } from '@mantine/core'
import { IconChevronLeft } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

type Props = {
  backTo?: string
  label?: string
}

const BackNavigation = ({
  backTo = routes.learningUnitLessons(),
  label,
}: Props) => {
  const isSmallScreen = useIsSmallScreen()

  const { t } = useTranslation('learning')
  const labelText = label
    ? t(label)
    : t(`common.${isSmallScreen ? 'exit' : 'back'}`)

  const goBackToUnit = () => {
    navigate(backTo)
  }

  return (
    <UnstyledButton onClick={goBackToUnit} data-sel="back-navigation">
      <Group gap={rem(5)}>
        <IconChevronLeft size={20} />
        <Text>{labelText}</Text>
      </Group>
    </UnstyledButton>
  )
}

export default BackNavigation
