import { AppShell, rem } from '@mantine/core'
import { IconHome } from '@tabler/icons-react'

import { routes } from '@redwoodjs/router'

import FooterMenu from 'src/components/learning/FooterMenu'
import Header from 'src/components/learning/Header/Header'
import TeacherProfileMenu from 'src/components/learning/TeacherProfileMenu'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
type props = {
  children?: React.ReactNode
}

export const menu = [
  {
    label: 'Home',
    route: '/learning/teacher/home',
  },
]

const footerMenu = [
  {
    label: 'Home',
    route: '/learning/teacher/home',
    icon: <IconHome size={20} />,
  },
]

const LearningTeacherLayout = ({ children }: props) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <AppShell bg={isSmallScreen ? 'white' : 'color-body'}>
      <AppShell.Main pb={isSmallScreen ? rem(120) : rem(20)} className="main">
        <Header menu={menu} logoTo={routes.learnginTeacherHomePage()}>
          <TeacherProfileMenu />
        </Header>
        {children}
      </AppShell.Main>
      {isSmallScreen && (
        <AppShell.Footer>
          <FooterMenu menu={footerMenu} />
        </AppShell.Footer>
      )}
    </AppShell>
  )
}

export default LearningTeacherLayout
