import { AppShell, rem } from '@mantine/core'

import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityProvider } from 'src/providers/learning/activity/ActivityProvider'
import { ActivityResolverProvider } from 'src/providers/learning/activity/ActivityResolverProvider'
import { LevelProvider } from 'src/providers/learning/level/LevelProvider'

type Props = {
  children?: React.ReactNode
}

const LearningTestLayout = ({ children }: Props) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <LevelProvider>
      <ActivityProvider>
        <ActivityResolverProvider>
          <AppShell
            bg={isSmallScreen ? 'white' : 'var(--mantine-color-body)'}
            footer={{ height: rem(100) }}
          >
            {children}
          </AppShell>
        </ActivityResolverProvider>
      </ActivityProvider>
    </LevelProvider>
  )
}

export default LearningTestLayout
