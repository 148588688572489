import { useEffect, useState } from 'react'

import {
  Group,
  Text,
  Anchor,
  Badge,
  Container,
  Table,
  Title,
  Tooltip,
  Card,
  TextInput,
  rem,
  ThemeIcon,
  Modal,
  Button,
  Loader,
  LoadingOverlay,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useDisclosure } from '@mantine/hooks'
import {
  IconArrowLeft,
  IconArrowsDiff,
  IconBrackets,
  IconCirclesRelation,
  IconEdit,
  IconListNumbers,
  IconSearch,
  IconTriangleSquareCircle,
  IconCirclePlus,
  IconTrash,
} from '@tabler/icons-react'

import { Link, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { useQuery } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import {
  LIST_ACTIVITIES,
  DELETE_ACTIVITY,
} from 'src/graphql/learning/learning-admin'

export const TYPOLOGIES = {
  BOOLEAN: {
    color: 'blue',
    label: 'Verdadeiro ou Falso',
    icon: IconArrowsDiff,
  },
  MULTIPLE_CHOICE: {
    color: 'cyan',
    label: 'Múltipla Escolha',
    icon: IconTriangleSquareCircle,
  },
  COMBINATION: {
    color: 'orange',
    label: 'Combinação',
    icon: IconCirclesRelation,
  },
  SEQUENCING: { color: 'teal', label: 'Ordenação', icon: IconListNumbers },
  FILL_IN_THE_BLANKS: {
    color: 'purple',
    label: 'Fill in the blanks',
    icon: IconBrackets,
  },
  WRITING: { color: 'red', label: 'Escrita', icon: IconEdit },
}

export const TypologyBadge = ({ typology }) => {
  const data = TYPOLOGIES[typology]
  if (!data) return

  return (
    <Badge color={data.color} fw={600}>
      {data.label}
    </Badge>
  )
}

export default function ActivitiesListPage() {
  const { data, loading } = useQuery(LIST_ACTIVITIES, {
    onCompleted: (data) => setActivities(data?.ManageActivitiesList || []),
  })

  const [deleteActivity, { loading: deleteActivityLoading }] =
    useMutation(DELETE_ACTIVITY)

  const [opened, { open, close }] = useDisclosure(false)
  const [activityId, setActivityId] = useState(null)
  const [confirmationWord, setConfirmationWord] = useState('')
  const [activities, setActivities] = useState([])
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebouncedValue(query, 500)

  const onActivityDelete = async () => {
    if (deleteActivityLoading) return

    const response = await deleteActivity({
      variables: {
        id: activityId,
      },
    })

    close()

    const { data } = response
    const { DeleteActivity } = data

    if (DeleteActivity && DeleteActivity?.id) {
      const activitiesFiltered = activities.filter(
        (activity) => activity.id !== activityId
      )
      setActivities(activitiesFiltered)
      setConfirmationWord('')
      notifications.success({
        title: 'Atividade deletada',
        message: 'A atividade foi deletada com sucesso.',
      })
    } else {
      notifications.error({
        title: 'Erro ao deletar atividade',
        message: 'Recarregue a página e tente novamente.',
      })
    }
  }

  const onConfirmDelete = (activityId) => {
    setActivityId(activityId)
    open()
  }

  useEffect(() => {
    if (loading) return
    if (debouncedQuery === '') {
      return setActivities(data?.FilterActivities)
    }
    if (!debouncedQuery) return

    const filteredActivities = data?.FilterActivities.filter(
      (activity) =>
        activity?.instruction_en
          ?.toLowerCase()
          .includes(debouncedQuery.toLowerCase()) ||
        activity.typology.toLowerCase().includes(debouncedQuery.toLowerCase())
    )
    setActivities(filteredActivities)
  }, [debouncedQuery, data, loading])

  return (
    <Container maw={rem(1140)}>
      <Anchor
        component={Link}
        underline="never"
        to={routes.learningAdminHomePage()}
      >
        <Group>
          <ThemeIcon radius="xl" size="md" variant="light">
            <IconArrowLeft size={16} />
          </ThemeIcon>

          <Text>Início</Text>
        </Group>
      </Anchor>

      <Group my="xl" justify="space-between" align="flex-end">
        <Group>
          <Title order={2}>Atividades</Title>
          <Anchor
            underline="never"
            component={Link}
            to={routes.learningAdminCreateActivity()}
          >
            <Group gap={rem(4)}>
              <Text>Nova atividade</Text>
              <IconCirclePlus />
            </Group>
          </Anchor>
        </Group>

        <TextInput
          leftSection={<IconSearch size={18} />}
          placeholder="Pesquisar atividades"
          onChange={(event) => setQuery(event.currentTarget.value)}
          miw={rem(350)}
        />
      </Group>

      <Card withBorder radius="lg">
        <LoadingOverlay visible={loading} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w={10}>#</Table.Th>
              <Table.Th>Instrução</Table.Th>
              <Table.Th>Tipo</Table.Th>
              <Table.Th w={100}></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {activities.map((activity) => (
              <Table.Tr key={activity.id}>
                <Table.Td>{activity.id}</Table.Td>
                <Table.Td>
                  <Text>{activity.instruction_en}</Text>
                </Table.Td>
                <Table.Td>
                  <TypologyBadge typology={activity.typology} />
                </Table.Td>
                <Table.Td>
                  <Group justify="flex-end">
                    <Tooltip inline label="Editar">
                      <Anchor
                        component={Link}
                        display="flex"
                        to={routes.learningAdminEditActivity({
                          id: activity.id,
                        })}
                      >
                        <IconEdit />
                      </Anchor>
                    </Tooltip>
                    <Tooltip inline label="Excluir">
                      <Anchor
                        onClick={() => {
                          onConfirmDelete(activity.id)
                        }}
                        display="flex"
                      >
                        <IconTrash />
                      </Anchor>
                    </Tooltip>
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Card>
      <Modal opened={opened} onClose={close}>
        <Title order={3}>Deletar atividade #{activityId}</Title>
        <Title pt={rem(20)} order={5}>
          Tem certeza que deseja deletar essa atividade? Essa operação não pode
          ser desfeita.
        </Title>
        <Title order={6}>
          Para confirmar digite{' '}
          <Text span fw={700}>
            DELETE
          </Text>{' '}
          no campo abaixo:
        </Title>
        <TextInput
          placeholder="Digite DELETE"
          mt={rem(10)}
          style={{ width: '100%' }}
          onChange={(e) => setConfirmationWord(e.target.value)}
        />
        <Group pt={rem(16)} justify="flex-end">
          <Button w={rem(180)} bg="cnaBlue.5" onClick={close}>
            Cancelar
          </Button>
          <Button
            disabled={confirmationWord !== 'DELETE'}
            w={rem(100)}
            onClick={onActivityDelete}
          >
            {deleteActivityLoading ? (
              <Loader size={rem(24)} color="white" c="white" />
            ) : (
              'Deletar'
            )}
          </Button>
        </Group>
      </Modal>
    </Container>
  )
}
