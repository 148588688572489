import store from 'store2'

function toLocaleSupportsLocales() {
  return (
    typeof Intl === 'object' &&
    !!Intl &&
    typeof Intl.DateTimeFormat === 'function'
  )
}

export const formatDate = (date: string, customLocale?: string): string => {
  if (!toLocaleSupportsLocales()) {
    return ''
  }

  const currentLanguage = store('currentLocale') || 'pt_BR'
  const storageLocale = currentLanguage === 'pt_BR' ? 'pt-BR' : 'en'
  const locale = customLocale || storageLocale

  const newDate = new Date(date)

  return newDate?.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

export const formatTime = (date: string, customLocale?: string): string => {
  if (!toLocaleSupportsLocales()) {
    return ''
  }

  const currentLanguage = store('currentLocale') || 'pt_BR'
  const storageLocale = currentLanguage === 'pt_BR' ? 'pt-BR' : 'en'
  const locale = customLocale || storageLocale

  const newDate = new Date(date)

  return newDate?.toLocaleTimeString(locale, { timeStyle: 'short' })
}

export const diffSeconds = (date1: Date, date2: Date): number => {
  const diff = date1.getTime() - date2.getTime()
  return Math.floor(diff / 1000)
}
