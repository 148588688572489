import {
  Anchor,
  Box,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  Title,
  rem,
} from '@mantine/core'
import { IconRefresh, IconUsers } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Maybe, TeacherSchoolClass } from 'types/graphql'

import { Link, useParams, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { GET_TEACHER_SCHOOL_CLASS } from 'src/graphql/learning/teacher'

type FindTeacherSchoolClass = {
  FindTeacherSchoolClass?: Maybe<TeacherSchoolClass>
}

type ClassSwitcherProps = {
  to?: string
}

const ClassSwitcher = ({
  to = routes.learningClassesPage(),
}: ClassSwitcherProps) => {
  const { t } = useTranslation('learning')
  const { classId } = useParams()

  const { data, loading } = useQuery<FindTeacherSchoolClass>(
    GET_TEACHER_SCHOOL_CLASS,
    {
      variables: {
        id: classId,
      },
      onError: (_) => {
        notifications.error({
          title: t('main.loading-error'),
          message: '',
        })
      },
    }
  )

  const classData = data?.FindTeacherSchoolClass

  return (
    <>
      <LoadingOverlay
        visible={loading}
        overlayProps={{ backgroundOpacity: 1 }}
      />
      <Anchor
        component={Link}
        to={to}
        underline="never"
        data-sel="change-class"
      >
        <Group justify="space-between">
          <Group>
            <IconUsers size={20} color="black" />
            <Box>
              <Text c="dark.3" size={rem(12)}>
                {t('teacher.my-classes')}
              </Text>
              {data?.FindTeacherSchoolClass?.id && (
                <Title order={6} c="black">
                  {`${classData?.name} - ${classData?.schoolName}`}
                </Title>
              )}
            </Box>
          </Group>
          <Paper withBorder px={rem(10)} py={rem(6)} radius="lg">
            <Group gap={rem(5)}>
              <Text c="black" size={rem(12)}>
                {t('common.change')}
              </Text>
              <IconRefresh size={12} color="black" />
            </Group>
          </Paper>
        </Group>
      </Anchor>
    </>
  )
}

export default ClassSwitcher
