import { SegmentedControl, Image, Flex, Text, rem } from '@mantine/core'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

type LanguageSelectProps = {
  language: string
  onLanguageChange: (value: string) => void
  forceSmallScreen?: boolean
}

const LanguageSelect = ({
  language,
  onLanguageChange,
  forceSmallScreen,
}: LanguageSelectProps) => {
  const isSmallScreen = useIsSmallScreen()

  const iconSize = forceSmallScreen || isSmallScreen ? 16 : 20
  const fontSize = forceSmallScreen || isSmallScreen ? rem(12) : rem(14)
  const toggleSize = forceSmallScreen || isSmallScreen ? 180 : 274

  const flagProps = {
    width: iconSize,
    height: iconSize,
    mr: 10,
    image: {
      en: '/flag-uk.svg',
      ptBr: '/flag-brazil.svg',
    },
  }

  return (
    <SegmentedControl
      data-sel="language-select"
      transitionDuration={100}
      w={toggleSize}
      radius={rem(30)}
      value={language}
      onChange={onLanguageChange}
      data={[
        {
          label: (
            <Flex data-sel="language-en" justify="center" align="center">
              <Image src={flagProps.image.en} {...flagProps} />
              <Text size={fontSize}>EN</Text>
            </Flex>
          ),
          value: 'en',
        },
        {
          label: (
            <Flex data-sel="language-pt-br" justify="center" align="center">
              <Image src={flagProps.image.ptBr} {...flagProps} />
              <Text size={fontSize}>BR</Text>
            </Flex>
          ),
          value: 'pt_BR',
        },
      ]}
    />
  )
}

export default LanguageSelect
