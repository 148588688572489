import {
  Title,
  Image,
  Text,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Paper,
  Avatar,
  Button,
  rem,
} from '@mantine/core'
import { format } from 'date-fns'

import { Link, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import ContainerWrap from 'src/components/shared/ContainerWrap'
import { GET_TEACHER_GRADE_STUDENT_NAME } from 'src/graphql/learning/teacher'
import { getInitials } from 'src/helpers/user'

type TeacherStudentGradeSummaryPageProps = {
  studentId: number
  grade: number
  gradedAt: string
  classId: number
}
const TeacherStudentGradeSummaryPage = ({
  studentId,
  grade,
  gradedAt,
  classId,
}: TeacherStudentGradeSummaryPageProps) => {
  const formattedDate = format(new Date(gradedAt), 'dd/MM/yyyy')
  const { data, loading } = useQuery(GET_TEACHER_GRADE_STUDENT_NAME, {
    variables: { id: studentId },
  })

  const { studentName } = data?.FindTeacherSchoolClassStudent || {}

  return (
    <>
      {loading ? (
        <LoadingOverlay visible />
      ) : (
        <Box bg="white" w="100%" h="100vh" data-sel="grade-summary">
          <ContainerWrap>
            <Center>
              <Box w="100%" maw={rem(800)}>
                <Center>
                  <Image
                    src="/learning/teacher-student-grade.png"
                    alt="Student grade summary"
                    w={rem(200)}
                    data-sel="grade-summary-image"
                  />
                </Center>
                <Title
                  order={4}
                  ta="center"
                  py={rem(32)}
                  data-sel="grade-summary-title"
                >
                  Prova corrigida!
                </Title>
                <Group justify="space-between">
                  <Group>
                    <Avatar
                      data-sel="grade-summary-avatar"
                      component="button"
                      size={48}
                      bg="cnaRed.6"
                      style={{ border: 'none' }}
                    >
                      <Text c="white" w="500">
                        {getInitials(studentName)}
                      </Text>
                    </Avatar>

                    <Box>
                      <Title data-sel="grade-summary-student-name" order={6}>
                        {studentName}
                      </Title>
                      <Text data-sel="grade-summary-graded-at" size={rem(10)}>
                        Corrigido em: {formattedDate}
                      </Text>
                    </Box>
                  </Group>
                  <Paper
                    radius={rem(48)}
                    w={rem(100)}
                    bg="green.1"
                    c="green.9"
                    py="sm"
                  >
                    <Text
                      data-sel="grade-summary-grade"
                      ta="center"
                      size={rem(12)}
                    >
                      NOTA {grade?.toFixed(2)}
                    </Text>
                  </Paper>
                </Group>
                <Center>
                  <Button
                    color="black"
                    data-sel="grade-summary-back-button"
                    variant="outline"
                    mt={rem(32)}
                    size="lg"
                    w="100%"
                    maw={rem(390)}
                    component={Link}
                    to={routes.learningTestClassStudentsPage({
                      classId,
                    })}
                  >
                    Voltar
                  </Button>
                </Center>
              </Box>
            </Center>
          </ContainerWrap>
        </Box>
      )}
    </>
  )
}

export default TeacherStudentGradeSummaryPage
