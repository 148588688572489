export function getInitials(name?: string) {
  if (!name) {
    return ''
  }

  const words = name.split(' ')

  const initials = words
    ? `${words[0].charAt(0)} ${
        words.length > 1 ? words[words.length - 1].charAt(0) : ''
      }`
    : ''
  return initials
}
