import {
  Highlight,
  Group,
  Anchor,
  Card,
  Center,
  Text,
  Box,
  rem,
} from '@mantine/core'
import { IconReportAnalytics, IconWriting } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import store from 'store2'

import { Link, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import ContainerWrap from 'src/components/shared/ContainerWrap'

const TeacherHomePage = () => {
  const { currentUser } = useAuth()
  const { name } = currentUser || { name: '' }

  const { t } = useTranslation('learning')

  const title = `${t('main.welcome')}, ${name}`
  const currentLocale = store.get('currentLocale')

  if (currentLocale !== 'en') {
    store.set('currentLocale', 'en')
  }

  return (
    <Box data-sel="teacher-home-page">
      <ContainerWrap>
        <Highlight
          data-sel="title"
          size="lg"
          highlight={name}
          highlightStyles={{
            WebkitBackgroundClip: 'text',
            color: 'var(--mantine-color-cnaRed-6)',
          }}
        >
          {title}
        </Highlight>
        <Group pt="xl" gap={rem(42)}>
          <Anchor
            component={Link}
            to={routes.learningClassesPage()}
            c="black"
            underline="never"
            data-sel="link"
          >
            <Card radius={rem(8)} bg="gray.1" p="md">
              <Center>
                <IconWriting size={24} color="var(--mantine-color-cnaRed-6)" />
              </Center>
            </Card>
            <Center>
              <Text pt="xs" size="xs">
                Writing
              </Text>
            </Center>
          </Anchor>
          <Anchor
            component={Link}
            to={routes.learningTestClassesPage()}
            c="black"
            underline="never"
            data-sel="link"
          >
            <Card radius={rem(8)} bg="gray.1" p="md">
              <Center>
                <IconReportAnalytics
                  size={24}
                  color="var(--mantine-color-cnaRed-6)"
                />
              </Center>
            </Card>
            <Center>
              <Text pt="xs" size="xs">
                Tests
              </Text>
            </Center>
          </Anchor>
        </Group>
      </ContainerWrap>
    </Box>
  )
}

export default TeacherHomePage
