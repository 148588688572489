import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { LoadingOverlay } from '@mantine/core'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Module } from 'types/graphql'

import { notifications } from 'src/components/shared/Notifications'
import { CourseSegmentEnum } from 'src/enums/learning/course/CourseSegmentEnum'
import { ModuleCategoryEnum } from 'src/enums/learning/module/ModuleCategoryEnum'
import { GET_MODULES, GET_COURSE_PROGRESS } from 'src/graphql/learning/learning'
import { orderedSubModules } from 'src/helpers/module'
import { getStoreLevel, setStoreLevel } from 'src/helpers/storage'
import { useLevel } from 'src/providers/learning/level/LevelProvider'

type Props = {
  children?: React.ReactNode
}

type LevelsData = {
  FilterModules?: Module[]
}

const Levels = ({ children }: Props) => {
  const { t } = useTranslation('learning')

  const { setCourseProgress, setLevels, setLevelsUnits, levels, currentLevel } =
    useLevel()

  const [getLevelsByCategory, { loading: levelsLoading, error: levelsError }] =
    useLazyQuery<LevelsData>(GET_MODULES)

  const [
    getCourseProgress,
    { loading: courseProgressLoading, error: coursesProgressError },
  ] = useLazyQuery(GET_COURSE_PROGRESS)

  useEffect(() => {
    if (levels.length) return

    const fetchCourseProgress = async (courseId: number) => {
      if (!courseId) return

      const response = await getCourseProgress({
        variables: { courseId: courseId },
      })
      const { data } = response
      const { CourseProgress } = data

      setCourseProgress(CourseProgress.modulesProgress)
    }

    const fetchLevels = async () => {
      const response = await getLevelsByCategory({
        variables: {
          courseSegment: CourseSegmentEnum.CNA_TEENS_UP,
          category: ModuleCategoryEnum.LEVEL,
        },
      })

      const { data } = response

      if (!data?.FilterModules && !data?.FilterModules[0]) return

      const { FilterModules } = data
      const storeLevel = getStoreLevel()
      const { course } = FilterModules[0]

      fetchCourseProgress(course?.id)

      const currentLevel =
        FilterModules?.find((level) => level.id === storeLevel?.id) ||
        FilterModules[0]

      setStoreLevel({ id: currentLevel.id, slug: currentLevel.slug })

      setLevels({
        levels: FilterModules,
        currentLevel: currentLevel,
      })
    }

    fetchLevels()
  }, [
    levels,
    getLevelsByCategory,
    setLevelsUnits,
    setLevels,
    getCourseProgress,
    setCourseProgress,
  ])

  useEffect(() => {
    if (!Object.keys(currentLevel)?.length) return

    const units = orderedSubModules(currentLevel)
    const commonUnits = units?.filter(
      (unit) => unit.category === ModuleCategoryEnum.UNIT
    )

    setLevelsUnits(commonUnits)
  }, [currentLevel, setLevelsUnits])

  useEffect(() => {
    if (levelsError || coursesProgressError) {
      notifications.error({
        title: t('main.loading-error'),
        message: '',
      })
    }
  }, [levelsError, coursesProgressError, t])

  return levelsLoading || courseProgressLoading ? (
    <LoadingOverlay overlayProps={{ backgroundOpacity: 1 }} visible />
  ) : (
    <Box data-sel={currentLevel?.slug}>{children}</Box>
  )
}

export default Levels
