import { Container, rem } from '@mantine/core'

import { useIsSmallScreen } from 'src/hooks/shared/screen'

type ContainerWrapProps = {
  children?: React.ReactNode
  fluid?: boolean
}

const ContainerWrap = ({ children, fluid = false }: ContainerWrapProps) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <Container
      pt={isSmallScreen ? rem(20) : rem(55)}
      maw={fluid ? '100%' : rem(1440)}
      px={fluid ? 0 : 'md'}
    >
      {children}
    </Container>
  )
}

export default ContainerWrap
